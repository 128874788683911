// IMPORT DEPENDENCIES
import { array, number, object, string, time } from "fast-web-kit";
import translate from "./translator";

// ENCRYPTION VARIABLES
window.Buffer = window.Buffer || require("buffer").Buffer;
const crypto = require("crypto-browserify");

// ENVIRONMENT VARIABLES
const environment = process.env;
const project_stage = environment.NODE_ENV;
const initialization_vector = Buffer.from(
  environment.REACT_APP_INITIALIZATION_VECTOR || "2d52550dc714657a",
  "utf8"
);
const encryption_key = Buffer.from(
  environment.REACT_APP_ENCRYPTION_KEY || "abcdefghijkLMNOPQrstuvwXyZSRMSRM",
  "utf8"
);
const encryption_type = environment.REACT_APP_ENCRYPTION_TYPE;
const encryption_algorithm = environment.REACT_APP_ENCRYPTION_ALGORITHM;
export const has_enable_encryption =
  environment.REACT_APP_ENABLE_ENCRYPTION === "true" ? true : false;

// APPLICATION NAME
export const applicationName = "EduBetter";

// SERVER PORT
const port = 6080;

// DOMAIN NAME
const domain = "https://edubetter.co.tz";

// SERVER URL
export const serverUrl = project_stage === "development" ? `http://localhost:${port}` : `${domain}`;

// socket url
export const socketURL = project_stage === "development" ? `http://localhost:${port}/api` : `${domain}/api`

// message senderId
export const smsSender = "EduBetter"

// message username
export const smsUsername = "edubetter"

// message API
export const smsAPI = "9febf29e31ffae61f708476854bd8aad5e9709f558d7d8cce00e18bffcebefe2"

// message ALPHANUMERICS
export const alphanumerics = ["EduBetter"]

// PAGE TITLE
export const setPageTitle = (title) => {
  document.title = translate(title);
};

// decryption function
export function decrypt(data) {
  try {
    const decipher = crypto.createDecipheriv(
      encryption_algorithm,
      encryption_key,
      initialization_vector
    );
    const decrypted = JSON.parse(
      decipher.update(data.payload, encryption_type, "utf8") +
      decipher.final("utf8")
    );
    return decrypted;
  } catch (error) {
    console.log(error.message);
    sessionStorage.clear();
    return { success: false, message: error.message };
  }
}

// encryption function
export function encrypt(data) {
  try {
    const cipher = crypto.createCipheriv(
      encryption_algorithm,
      encryption_key,
      initialization_vector
    );
    const payload =
      cipher.update(JSON.stringify(data), "utf8", encryption_type) +
      cipher.final(encryption_type);
    return { payload };
  } catch (error) {
    console.log(error.message);
    sessionStorage.clear();
    return { payload: error.message };
  }
}

// local storage operation
export const storage = {
  clear: () => sessionStorage.clear(),
  retrieve: (key) => {
    const payload = sessionStorage.getItem(key);

    if (payload) {
      const data = decrypt({ payload: JSON.parse(payload) });
      return data;
    }
    return payload;
  },
  store: (key, data) =>
    sessionStorage.setItem(key, JSON.stringify(encrypt(data).payload)),
  remove: (key) => sessionStorage.removeItem(key),
};

/* creating and exporting  function for getting specific local storage data information */
export const getInfo = (key, info) => {
  // retrieving data from local storage
  const data = storage.retrieve(key);

  // verifying data is available
  if (data)
    if (info?.trim())
      // verifying data information has been provided
      // returning requsted info
      return data[info];
    // data info is not provided
    // returning all data information NOTE: same as storage.retrieve() function
    else return data;
  else return null;
};

export const user = getInfo("user");

// is admin
export const isAdmin = user && user.account_type === "admin";

// is IT
export const isIT = user && (user.account_type === "IT" ? true : false);

export const isDriver = user && (user.account_type === "driver" ? true : false);

// is parent
export const isParent = user && (user.account_type === "parent" ? true : false);

// is teacher
export const isTeacher = user && (user.account_type === "teacher" ? true : false);

// is manager
export const isManager = user && (user.account_type === "manager" ? true : false);

// is academic
export const isAcademic = user && (user.account_type === "academic" ? true : false);

// is librarian
export const isLibrarian = user && (user.account_type === "librarian" ? true : false);

export const isSecretary = user && (user.account_type === "secretary" ? true : false);

export const isAccountant = user && (user.account_type === "accountant" ? true : false);

export const isOther = user && (user.account_type === "other" ? true : false);

export const isDiscipline = user && (user.account_type === "descipline_master" ? true : false);

// is head teacher
export const isHOS = user && (user.account_type === "head_teacher" ? true : false);


// function to check the permissions
export function can(permission) {
  try {
    const userRoles = getInfo("user", "role");
    const userSchoolId = getInfo("user", "school")?._id;

    if (array.isEmpty(userRoles)) return true
    else if (!array.isEmpty(userRoles) && userSchoolId) {
      permission = text.format(permission);

      // Filter roles by the current school
      const rolesForSchool = userRoles.filter(userRoleObj => userRoleObj.school && userRoleObj.school._id === userSchoolId);

      // Check each role in the filtered roles
      for (const userRoleObj of rolesForSchool) {
        const userRole = userRoleObj.role;
        // Check if the permission exists in the current role's permissions
        if (userRole.permissions.indexOf(permission) >= 0) {
          return true;
        }
      }

      // If none of the roles had the permission
      return false;
    } else {
      return false;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
}


// get user informations
export function getUserInfo(info) {
  try {
    const user = storage.retrieve("user");

    if (user) {
      if (info) {
        const parsedUser = JSON.parse(user);
        return parsedUser[info];
      } else return JSON.parse(user);
    } else return false;
  } catch (error) {
    console.error(error);
  }
}

// Text format
export const text = {
  format: (text) => {
    if (text) return string.toSnakeCase(text);
    return text;
  },
  reFormat: (text) => {
    if (text) return string.removeCase(text, "snake_case");
    return text;
  },
  toTitle: (text) => {
    if (text) return string.toTitleCase(text);
    return text;
  },
  toUpper: (text) => {
    if (text) return text.toUpperCase();
    return text;
  },
};

// format number
export const numeral = {
  format: (numeral) => {
    if (numeral) return number.formatNumber(numeral);
    return numeral;
  },
};

// JSON OBJECT
export function ToJSON(obj) {
  try {
    const jsonString = object.stringify(obj);
    return jsonString;
  } catch (error) {
    console.error(error);
    return null;
  }
}

//  get condition
export const getCondition = (schema = null) => {
  try {
    if (user) {
      const condition = { visible: true, $expr: {} };
      const pathname = window.location.pathname;

      if (isAdmin && array.isEmpty(user.role)) return condition;

      if (
        isParent ||
        (!array.isEmpty(user.students) && isLibrarian) ||
        (!array.isEmpty(user.students) && isDriver) ||
        (!array.isEmpty(user.students) && isOther) ||
        (!array.isEmpty(user.students) && isAccountant) ||
        (!array.isEmpty(user.students) && isIT)
      ) {
        if (pathname === "/list-student") {
          return {
            ...condition,
            $and: [
              { _id: { $in: user.students } },
              { school: user.school._id },
            ],
          };
        }
        if (pathname === "/history-invoice" || pathname === "/list-invoice") {
          return {
            ...condition,
            $and: [
              { student: { _id: { $in: user.students } } },
              { school: user.school._id },
            ],
          };
        }
        if (pathname === "/dashboard") {
          if (schema === "news" || schema === "exam") {
            return {
              ...condition,
              $and: [{ school: user.school._id }],
            };
          }
          return {
            ...condition,
            $or: [{ _id: { $in: user.students } }],
          };
        }
        return {
          ...condition,
          $and: [{ school: user.school._id }],
        };
      }

      if (isTeacher) {
        if (pathname === "/dashboard") {
          if (schema === "news") {
            return {
              ...condition,
              $and: [{ school: user.school._id }],
            };
          }
          return {
            ...condition,
            $or: [{ _id: { $in: user.students } }],
          };
        }
        return {
          ...condition,
          $and: [{ school: user.school._id }],
        };
      }

      if (pathname === "/list-school") {
        return {
          ...condition,
          $and: [{ _id: user.school._id }],
        };
      }

      return {
        ...condition,
        $and: [{ school: user.school?._id }],
      };
    }

    return { error: "no user" };
  } catch (error) {
    console.log(error.message);
    return { error: error.message };
  }
};



// Function to convert a number to Roman numeral
export const toRoman = (num) => {
  try {
    const lookup = {
      M: 1000,
      CM: 900,
      D: 500,
      CD: 400,
      C: 100,
      XC: 90,
      L: 50,
      XL: 40,
      X: 10,
      IX: 9,
      V: 5,
      IV: 4,
      I: 1
    };
    let roman = '';
    for (const i in lookup) {
      const q = Math.floor(num / lookup[i]);
      num -= q * lookup[i];
      roman += i.repeat(q);
    }
    return roman;
  } catch (error) {
    console.log(error.message);
    return { error: error.message };
  }
};

// school levels
export const levels = [
  "Nursery",
  "Primary",
  "Lower Secondary (CHECKPOINT)",
  "Upper Secondary (IGCSE)",
  "Advanced Subsidiary (As)",
  "A level (A2)",
  "O level",
  "A level",
  "Diploma",
  "Certificate",
  "NTA",
  "NVA"
];

// result format
export const resultFormat = [
  "CSEE",
  "ACSEE",
  "PSLE",
  "Cambridge",
  "College",
  "Competence"
]

// subject type
export const subjectType = [
  "core",
  "option"
]

// gender
export const gender = [
  "male",
  "female"
]

// account type
export const accountType = [
  "IT",
  "academic master",
  "accountant",
  "admin",
  "class teacher",
  "cook",
  "discipline master",
  "driver",
  "electrician",
  "gardener",
  "head teacher",
  "lab technician",
  "librarian",
  "manager",
  "matron",
  "nurse",
  "parent",
  "patron",
  "second master/mistress",
  "secretary",
  "security",
  "sponsor",
  "store keeper",
  "teacher"
];

// teacher
export const teacherAccountType = [
  "academic master",
  "discipline master",
  "head teacher",
  "teacher"
];

// countries
export const countries = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo, Democratic Republic of the",
  "Congo, Republic of the",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "East Timor",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea, North",
  "Korea, South",
  "Kosovo",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "North Macedonia",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe"
];

// regions
export const tanzaniaRegions = [
  "Arusha",
  "Dar es Salaam",
  "Dodoma",
  "Geita",
  "Iringa",
  "Kagera",
  "Katavi",
  "Kigoma",
  "Kilimanjaro",
  "Lindi",
  "Manyara",
  "Mara",
  "Mbeya",
  "Morogoro",
  "Mtwara",
  "Mwanza",
  "Njombe",
  "Pemba North",
  "Pemba South",
  "Pwani",
  "Rukwa",
  "Ruvuma",
  "Shinyanga",
  "Simiyu",
  "Singida",
  "Tabora",
  "Tanga",
  "Zanzibar North",
  "Zanzibar South and Central",
  "Zanzibar West"
];

export const tanzaniaDistricts = {
  "Arusha": ["Arusha", "Arumeru", "Karatu", "Longido", "Monduli", "Ngorongoro"],
  "Dar es Salaam": ["Ilala", "Kinondoni", "Temeke", "Kigamboni", "Ubungo"],
  "Dodoma": ["Bahi", "Chamwino", "Chemba", "Dodoma Urban", "Kondoa", "Kongwa", "Mpwapwa"],
  "Geita": ["Bukombe", "Chato", "Geita", "Mbogwe", "Nyang'hwale"],
  "Iringa": ["Iringa Rural", "Iringa Urban", "Kilolo", "Mafinga Town", "Mufindi"],
  "Kagera": ["Biharamulo", "Bukoba Rural", "Bukoba Urban", "Karagwe", "Kyerwa", "Missenyi", "Muleba", "Ngara"],
  "Katavi": ["Mpanda Town", "Mpanda Rural", "Nsimbo"],
  "Kigoma": ["Buhigwe", "Kakonko", "Kasulu Rural", "Kasulu Town", "Kibondo", "Kigoma Rural", "Kigoma Urban", "Uvinza"],
  "Kilimanjaro": ["Hai", "Moshi Rural", "Moshi Urban", "Mwanga", "Rombo", "Same", "Siha"],
  "Lindi": ["Kilwa", "Lindi Rural", "Lindi Urban", "Liwale", "Nachingwea", "Ruangwa"],
  "Manyara": ["Babati Rural", "Babati Town", "Hanang", "Kiteto", "Mbulu", "Simanjiro"],
  "Mara": ["Bunda", "Butiama", "Musoma Rural", "Musoma Urban", "Rorya", "Serengeti", "Tarime"],
  "Mbeya": ["Busokelo", "Chunya", "Kyela", "Mbarali", "Mbeya Rural", "Mbeya Urban", "Rungwe"],
  "Morogoro": ["Gairo", "Kilombero", "Kilosa", "Morogoro Rural", "Morogoro Urban", "Mvomero", "Ulanga", "Malinyi"],
  "Mtwara": ["Lindi", "Masasi", "Mtwara Rural", "Mtwara Urban", "Nachingwea", "Newala", "Tandahimba"],
  "Mwanza": ["Ilemela", "Kwimba", "Magu", "Misungwi", "Nyamagana", "Sengerema", "Ukerewe"],
  "Njombe": ["Ludewa", "Makambako Town", "Makete", "Njombe Rural", "Njombe Town", "Wanging'ombe"],
  "Pemba North": ["Micheweni", "Wete"],
  "Pemba South": ["Chake Chake", "Mkoani"],
  "Pwani": ["Bagamoyo", "Kibaha Town", "Kibaha", "Kisarawe", "Mafia", "Mkuranga", "Rufiji"],
  "Rukwa": ["Kalambo", "Nkasi", "Sumbawanga Rural", "Sumbawanga Urban"],
  "Ruvuma": ["Mbinga", "Nyasa", "Songea Rural", "Songea Urban", "Tunduru"],
  "Shinyanga": ["Kahama Rural", "Kahama Town", "Kishapu", "Shinyanga Rural", "Shinyanga Urban"],
  "Simiyu": ["Bariadi", "Busega", "Itilima", "Maswa", "Meatu"],
  "Singida": ["Ikungi", "Iramba", "Manyoni", "Mkalama", "Singida Rural", "Singida Urban"],
  "Tabora": ["Igunga", "Kaliua", "Nzega", "Sikonge", "Tabora Urban", "Urambo"],
  "Tanga": ["Handeni Rural", "Handeni Town", "Kilindi", "Korogwe Rural", "Korogwe Town", "Lushoto", "Mkinga", "Muheza", "Pangani", "Tanga"],
  "Zanzibar North": ["Kaskazini A", "Kaskazini B"],
  "Zanzibar South and Central": ["Kati", "Kusini"],
  "Zanzibar West": ["Magharibi", "Mjini"]
};

// contract
export const contractTypes = [
  "full time",
  "part time"
]

// religions
export const religions = [
  "Christianity",
  "Islam",
  "Hinduism",
  "Buddhism",
  "Sikhism",
  "Judaism",
  "Bahá'í",
  "Jainism",
  "Shinto",
  "Zoroastrianism",
  "Taoism",
  "Confucianism"
  // Add more religions as needed
];


// conditions
export const physicalConditions = [
  "Alcers",
  "Allergies",
  "Asthma",
  "Arthritis",
  "Back pain",
  "Cancer",
  "Chronic fatigue syndrome",
  "Chronic obstructive pulmonary disease (COPD)",
  "Diabetes",
  "Epilepsy",
  "Fibromyalgia",
  "Heart disease",
  "High blood pressure",
  "Irritable bowel syndrome (IBS)",
  "Kidney disease",
  "Liver disease",
  "Migraine",
  "Multiple sclerosis (MS)",
  "Normal",
  "Osteoporosis",
  "Parkinson's disease",
  "Psoriasis",
  "Rheumatoid arthritis",
  "Sleep apnea",
  "Stroke",
  "Thyroid disorders"
];

// insurance
export const healthInsurance = [
  "National Health Insurance Fund (NHIF)",
  "Jubilee Insurance",
  "AAR Insurance",
  "Strategis Insurance",
  "Metropolitan Tanzania",
  "Britam Insurance",
  "Alliance Insurance",
  "First Assurance",
  "Mayfair Insurance",
  "Resolution Insurance",
  "Sanlam Life Insurance",
  "Strategis Insurance",
  "Maxinsure",
  "Phoenix of Tanzania Assurance",
  "Puma Insurance",
  "Other"
];

// edu levels
export const educationLevels = [
  "Pre-primary education",
  "Primary education",
  "Secondary education",
  "Certificate",
  "Diploma",
  "Bachelor's degree",
  "Postgraduate diploma",
  "Master's degree",
  "Doctorate (PhD)"
];


// generate password
export function generateRandomPassword() {
  try {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let password = '';

    for (let i = 0; i < 6; i++) {
      const randomIndex = Math.floor(Math.random() * chars.length);
      password += chars[randomIndex];
    }

    return password;
  } catch (error) {
    console.log(`Password generation error: ${error.message}`)
  }
}

// banks
export const banks = [
  "ABC Bank",
  "Absa Bank Tanzania",
  "Access Bank",
  "Akiba Commercial Bank",
  "Amana Bank",
  "Azania Bank",
  "Bank of Africa Tanzania Limited",
  "Bank of Baroda",
  "Bank of India",
  "Barclays Bank",
  "BCP Bank Tanzania",
  "Canara Bank",
  "China Dasheng Bank",
  "Citibank",
  "CRDB Bank Plc",
  "DCB Commercial Bank",
  "Diamond Trust Bank",
  "DNB Bank (Tanzania) Limited",
  "Ecobank",
  "Equity Bank",
  "Exim Bank",
  "FBH Bank Tanzania Plc",
  "Finca Microfinance Bank",
  "First National Bank (FNB)",
  "Guaranty Trust Bank (Tanzania) Limited",
  "Habib African Bank",
  "I&M Bank",
  "International Commercial Bank (ICB)",
  "KCB Bank",
  "Kilimanjaro Cooperative Bank",
  "Letshego Bank",
  "Maendeleo Bank",
  "Mkombozi Commercial Bank (MKCB)",
  "Mufindi Community Bank (MuCoBa)",
  "Mwalimu Commercial Bank (MCB)",
  "Mwanga Hakika Bank",
  "National Bank of Commerce (NBC)",
  "NCBA",
  "NIC Bank",
  "NMB Bank Plc",
  "People's Bank of Zanzibar Plc",
  "Stanbic Bank Tanzania Limited",
  "Standard Chartered Bank Plc (Tanzania)",
  "Tandahimba Cooperative Savings and Credit Society Limited",
  "Tandahimba Community Bank",
  "Tanzania Agricultural Development Bank (TADB)",
  "Tanzania Commercial Bank (TCB)",
  "Tanzania Investment Bank (TIB)",
  "Tanzania Postal Bank Plc",
  "UBA Tanzania Limited",
  "Uchumi Commercial Bank",
  "Zana Bank Plc",
  "M-Pesa",
  "Tigo Pesa",
  "Airtel Money",
  "HaloPesa",
  "Ezy Pesa",
  "TTCL Pesa"
];


export const currencies = [
  "Argentine Peso (ARS)",
  "Australian Dollar (AUD)",
  "Brazilian Real (BRL)",
  "British Pound Sterling (GBP)",
  "Canadian Dollar (CAD)",
  "Chilean Peso (CLP)",
  "Chinese Yuan (CNY)",
  "Colombian Peso (COP)",
  "Danish Krone (DKK)",
  "Egyptian Pound (EGP)",
  "Euro (EUR)",
  "Ghanaian Cedi (GHS)",
  "Hong Kong Dollar (HKD)",
  "Indian Rupee (INR)",
  "Indonesian Rupiah (IDR)",
  "Israeli Shekel (ILS)",
  "Japanese Yen (JPY)",
  "Kenyan Shilling (KES)",
  "Malaysian Ringgit (MYR)",
  "Mexican Peso (MXN)",
  "New Zealand Dollar (NZD)",
  "Nigerian Naira (NGN)",
  "Norwegian Krone (NOK)",
  "Philippine Peso (PHP)",
  "Polish Zloty (PLN)",
  "Peruvian Sol (PEN)",
  "Russian Ruble (RUB)",
  "Saudi Riyal (SAR)",
  "Singapore Dollar (SGD)",
  "South African Rand (ZAR)",
  "South Korean Won (KRW)",
  "Swedish Krona (SEK)",
  "Swiss Franc (CHF)",
  "Tanzanian Shilling (TZS)",
  "Thai Baht (THB)",
  "Turkish Lira (TRY)",
  "Ugandan Shilling (UGX)",
  "US Dollar (USD)",
  "Vietnamese Dong (VND)",
  "United Arab Emirates Dirham (AED)"
];

// necta exam types 
export const nectaExamTypes = [
  "SFNA",
  "PSLE",
  "FTNA",
  "QT",
  "CSEE",
  "ACSEE",
  "GATCE",
  "GATSCCE",
  "DTE",
  "DSEE"
]

// parent type
export const parentType = [
  "Mother",
  "Father",
  "Uncle",
  "Aunt",
  "Brother",
  "Sister",
  "Single mother",
  "Single father",
  "Sponsor",
  "Guardian",
  "Other"
]

// previous school type
export const previousSchoolType = [
  "government",
  "non government"
]

// timetable types
export const timetableTypes = [
  "master",
  "specific"
]


// Timetable type
export const timetableCategories = ["class", "exam"]

// , "accounting"
export const smsCriteria = ["class"]

// sms source
export const communicationSource = [
  "from system",
  "input number/email",
]

// professions
export const professions = [
  "Accountant",
  "Actor",
  "Actuary",
  "Advertising Manager",
  "Aerospace Engineer",
  "Agricultural Engineer",
  "Air Traffic Controller",
  "Architect",
  "Archivist",
  "Art Director",
  "Artist",
  "Astronomer",
  "Athlete",
  "Audiologist",
  "Author",
  "Banker",
  "Barber",
  "Biologist",
  "Biomedical Engineer",
  "Bricklayer",
  "Broadcast Technician",
  "Budget Analyst",
  "Building Inspector",
  "Bus Driver",
  "Business Analyst",
  "Butcher",
  "Cabinetmaker",
  "Carpenter",
  "Cartographer",
  "Chef",
  "Chemical Engineer",
  "Chemist",
  "Chiropractor",
  "Civil Engineer",
  "Claims Adjuster",
  "Clinical Laboratory Technician",
  "Coach",
  "Commercial Diver",
  "Commercial Pilot",
  "Composer",
  "Computer Hardware Engineer",
  "Computer Programmer",
  "Computer Support Specialist",
  "Conservation Scientist",
  "Construction Manager",
  "Consultant",
  "Content Creator",
  "Copywriter",
  "Cost Estimator",
  "Counselor",
  "Court Reporter",
  "Credit Analyst",
  "Curator",
  "Customer Service Representative",
  "Data Analyst",
  "Data Scientist",
  "Database Administrator",
  "Dentist",
  "Designer",
  "Dietitian",
  "Doctor",
  "Driver",
  "Economist",
  "Editor",
  "Education Administrator",
  "Electrical Engineer",
  "Electrician",
  "Elementary School Teacher",
  "Elevator Installer",
  "Engineer",
  "Environmental Engineer",
  "Environmental Scientist",
  "Event Planner",
  "Executive",
  "Farmer",
  "Fashion Designer",
  "Film Director",
  "Financial Analyst",
  "Financial Manager",
  "Firefighter",
  "Fisher",
  "Fitness Trainer",
  "Florist",
  "Food Scientist",
  "Forensic Scientist",
  "Forester",
  "Game Developer",
  "General Manager",
  "Geneticist",
  "Geographer",
  "Geologist",
  "Graphic Designer",
  "Hairdresser",
  "Health Educator",
  "Healthcare Administrator",
  "Historian",
  "Home Health Aide",
  "Horticulturist",
  "Hospitality Manager",
  "Hotel Manager",
  "Human Resources Manager",
  "HVAC Technician",
  "Industrial Designer",
  "Industrial Engineer",
  "Information Security Analyst",
  "Information Technology (IT) Specialist",
  "Inspector",
  "Insurance Agent",
  "Interior Designer",
  "Interpreter",
  "Investment Banker",
  "Janitor",
  "Journalist",
  "Judge",
  "Laboratory Technician",
  "Landscaper",
  "Lawyer",
  "Librarian",
  "Logistician",
  "Machine Operator",
  "Machinist",
  "Magician",
  "Maintenance Worker",
  "Makeup Artist",
  "Management Analyst",
  "Manufacturing Engineer",
  "Marine Biologist",
  "Market Research Analyst",
  "Marketing Manager",
  "Massage Therapist",
  "Mathematician",
  "Mechanical Engineer",
  "Mechanic",
  "Medical Assistant",
  "Medical Laboratory Technician",
  "Medical Scientist",
  "Mental Health Counselor",
  "Meteorologist",
  "Microbiologist",
  "Military Officer",
  "Miner",
  "Model",
  "Music Producer",
  "Musician",
  "Nail Technician",
  "Network Administrator",
  "Nurse",
  "Nutritionist",
  "Occupational Therapist",
  "Office Manager",
  "Operations Manager",
  "Optometrist",
  "Painter",
  "Paramedic",
  "Paralegal",
  "Park Ranger",
  "Pathologist",
  "Pediatrician",
  "Personal Trainer",
  "Pharmacist",
  "Photographer",
  "Physical Therapist",
  "Physician",
  "Physician Assistant",
  "Physicist",
  "Pilot",
  "Plumber",
  "Police Officer",
  "Political Scientist",
  "Postal Worker",
  "Project Manager",
  "Property Manager",
  "Psychiatrist",
  "Psychologist",
  "Public Relations Specialist",
  "Radiologic Technologist",
  "Real Estate Agent",
  "Receptionist",
  "Recruiter",
  "Registered Nurse",
  "Research Scientist",
  "Respiratory Therapist",
  "Restaurant Manager",
  "Retail Manager",
  "Sales Manager",
  "Sales Representative",
  "Sanitation Worker",
  "School Counselor",
  "School Principal",
  "Scientist",
  "Secretary",
  "Security Guard",
  "Server",
  "Social Media Manager",
  "Social Worker",
  "Software Developer",
  "Software Engineer",
  "Sound Engineer",
  "Speech-Language Pathologist",
  "Statistician",
  "Stockbroker",
  "Substance Abuse Counselor",
  "Surgeon",
  "Surveyor",
  "Systems Analyst",
  "Systems Engineer",
  "Teacher",
  "Technical Writer",
  "Technician",
  "Telemarketer",
  "Therapist",
  "Translator",
  "Travel Agent",
  "Truck Driver",
  "Tutor",
  "Urban Planner",
  "Veterinarian",
  "Video Editor",
  "Video Game Designer",
  "Videographer",
  "Waiter",
  "Web Developer",
  "Web Designer",
  "Welder",
  "Writer",
  "Zoologist"
];


export const employerTypes = [
  "Corporate",
  "Freelancer",
  "Government",
  "International Organization",
  "Non-Governmental Organization (NGO)",
  "Non-Profit Organization",
  "Private Institution",
  "Public Sector",
  "Self-Employed",
  "Small Business",
  "Start-Up",
  "State-Owned Enterprise"
];

export const studentStatus = [
  "has student(s) in the system",
  "has no student(s) in the system"
]

export const sponsorTypes = [
  'Government',
  'Non-Government',
  'Corporate',
  'Company',
  'Individual',
  'Educational Institutions',
  'Foundations',
  'Community Organizations'
];

// allowance category
export const allowanceCategories = [
  "fixed",
  "monthly"
]

// tax type
export const tax = [
  "taxable",
  "non taxable"
]

export const pensionStatus = [
  "included",
  "not included"
]

// pension funds
export const pensionFunds = [
  "NSSF",
  "PPF",
  "LAPF",
  "PSPF",
  "GEPF",
  "ZSSF",
  "PSSF",
  "NHIF",
  "WCF"
]

export const salaryType = [
  "Basic salary",
  "Gross salary"
]

//  Report type
export const reportTypes = [
  "single",
  "combined"
];

// calculate report
export const combinedReport = (marks, exams, subjectCount, academicYear, classroom, term, type, include_division, name, closing_date, opening_date) => {
  try {
    // initialize variables
    const studentAverages = {};
    const studentAverages2 = {};
    const AveragesBySubject = {};

    const information = [];

    // Iterate over each mark
    marks.forEach((mark) => {
      // Get student id
      const studentId = mark.student._id;

      // Check if student info already exists in information array
      let studentInfo = information.find((info) => info.student._id === studentId);

      // If student info doesn't exist, create it
      if (!studentInfo) {
        studentInfo = {
          term,
          type,
          name,
          total: 0,
          average: 0,
          average2: 0,
          position: 0,
          position2: 0,
          closing_date,
          opening_date,
          positionStream: 0,
          positionStream2: 0,
          classroom: classroom,
          student: mark.student,
          academic_year: academicYear,
          created_by: getInfo("user", "_id"),
          school: getInfo("user", "school")?._id,
          include_division: include_division === "true" ? true : false,
          info: {
            score: [],
            averageBySubject: [],
          },
        };

        // Push the student info to the information array
        information.push(studentInfo);
      }

      // get number of marked subjects
      const numOfMarkedSubjects = mark.marks.filter((m) => m.mark != null).length;

      // iterate over each exam to get marks and points
      exams.forEach((exam) => {
        const weight = exam.weight || 100;

        if (mark.exam._id === exam.exam) {
          const adjustedMarks = mark.marks.map((subjectMark) => {
            const examMark = subjectMark.mark || 0;
            const adj = examMark * (weight / 100);

            AveragesBySubject[mark.student._id] = adj;

            return {
              adjustedMark: adj,
              points: subjectMark.points || 0,
              subject: subjectMark.subject._id,
            };
          });

          // calculate total marks
          const totalMarks = adjustedMarks.reduce((sum, subjectMark) => sum + (subjectMark.adjustedMark || 0), 0);

          // calculate first average according to number of subjects assigned to student
          const averageMark = subjectCount > 0 ? totalMarks / subjectCount : 0;

          // calculate second average according to number of marked subjects
          const averageMark2 = numOfMarkedSubjects > 0 ? totalMarks / numOfMarkedSubjects : 0;

          // assing averages on their respective variables
          studentAverages[studentId] = averageMark;
          studentAverages2[studentId] = averageMark2;

          const termScores = studentInfo.info;

          const existingExamIndex = termScores.score.findIndex((score) => score.exam === exam.exam);

          if (existingExamIndex !== -1) {
            termScores.score[existingExamIndex] = {
              visible: true,
              division: null,
              weight: weight,
              posAvg1Class: 0,
              posAvg2Class: 0,
              exam: exam.exam,
              posAvg1Stream: 0,
              posAvg2Stream: 0,
              marks: adjustedMarks,
              totalMarks: totalMarks,
              averageMark: averageMark,
              averageMark2: averageMark2,
            };
          } else {
            termScores.score.push({
              visible: true,
              division: null,
              weight: weight,
              posAvg1Class: 0,
              exam: exam.exam,
              posAvg2Class: 0,
              posAvg1Stream: 0,
              posAvg2Stream: 0,
              marks: adjustedMarks,
              totalMarks: totalMarks,
              averageMark: averageMark,
              averageMark2: averageMark2,
            });
          }
        }
      })
    })

    information.forEach((studentInfo) => {
      const subjectSumMap = new Map();

      studentInfo.info.score.forEach((score) => {
        score.marks.forEach((mark) => {
          const adjustedMark = mark.adjustedMark || 0;
          const subjectId = mark.subject;

          if (subjectSumMap.has(subjectId)) {
            subjectSumMap.set(subjectId, subjectSumMap.get(subjectId) + adjustedMark);
          } else {
            subjectSumMap.set(subjectId, adjustedMark);
          }
        });
      })

      studentInfo.info.averageBySubject = Array.from(subjectSumMap.entries()).map(([subjectId, sum]) => {
        const numOfExams = exams.length;
        const averageSubject = {
          pointsScore: 0,
          gradeScore: null,
          positionScore: 0,
          positionScoreStream: 0,
          score: sum / numOfExams,
          gradingRemarkScore: null,
          averageSubject: subjectId,
        };

        return averageSubject;
      })
    });

    function calculatePosition(scores, property) {
      scores.forEach((student, index) => {
        if (index > 0 && student.average === scores[index - 1].average) {
          student[property] = scores[index - 1][property];
        } else {
          student[property] = index + 1;
        }

        const studentInfo = information.find((info) => info.student._id === student.studentId);
        studentInfo.info.score.forEach((exam) => {
          exam[property] = student[property];
        });
      });
    }

    const classroomAverages = {};
    information.forEach((studentInfo) => {
      const studentId = studentInfo.student._id;
      const classroomId = studentInfo.classroom;
      if (!classroomAverages[classroomId]) {
        classroomAverages[classroomId] = [];
      }
      classroomAverages[classroomId].push({ studentId, average: studentAverages[studentId] });
    });

    for (const classId in classroomAverages) {
      classroomAverages[classId].sort((a, b) => b.average - a.average);
      calculatePosition(classroomAverages[classId], 'posAvg1Class');
    }

    const classroomAverages2 = {};
    information.forEach((studentInfo) => {
      const studentId = studentInfo.student._id;
      const classroomId = studentInfo.classroom;
      if (!classroomAverages2[classroomId]) {
        classroomAverages2[classroomId] = [];
      }
      classroomAverages2[classroomId].push({ studentId, average: studentAverages2[studentId] });
    });

    for (const classId in classroomAverages2) {
      classroomAverages2[classId].sort((a, b) => b.average - a.average);
      calculatePosition(classroomAverages2[classId], 'posAvg2Class');
    }

    const streamAverage = {};
    information.forEach((studentInfo) => {
      const studentId = studentInfo.student._id;
      const streamId = studentInfo.student?.stream;
      if (!streamAverage[streamId]) {
        streamAverage[streamId] = [];
      }
      streamAverage[streamId].push({ studentId, average: studentAverages[studentId] });
    });

    for (const streamId in streamAverage) {
      streamAverage[streamId].sort((a, b) => b.average - a.average);
      calculatePosition(streamAverage[streamId], 'posAvg1Stream');
    }

    const streamAverage2 = {};
    information.forEach((studentInfo) => {
      const studentId = studentInfo.student._id;
      const streamId = studentInfo.student?.stream;
      if (!streamAverage2[streamId]) {
        streamAverage2[streamId] = [];
      }
      streamAverage2[streamId].push({ studentId, average: studentAverages2[studentId] });
    });

    for (const streamId in streamAverage2) {
      streamAverage2[streamId].sort((a, b) => b.average - a.average);
      calculatePosition(streamAverage2[streamId], 'posAvg2Stream');
    }

    // Create a map to store scores for each student and subject
    const studentSubjectScores = new Map();

    information.forEach((studentInfo) => {
      const allSubjects = studentInfo.info.averageBySubject;

      allSubjects.forEach((subject) => {
        const subjectId = subject.averageSubject;
        const studentId = studentInfo.student._id;

        // Initialize the map entry if not exists
        if (!studentSubjectScores.has(studentId)) {
          studentSubjectScores.set(studentId, new Map());
        }

        // Store the score for each subject
        studentSubjectScores.get(studentId).set(subjectId, subject.score);
      });
    });

    // Iterate over each subject and assign positions for each student based on score
    const allSubjects = information.reduce((subjects, studentInfo) => {
      const subjectsForStudent = studentInfo.info.averageBySubject;
      subjectsForStudent.forEach((subject) => {
        const subjectId = subject.averageSubject;
        if (!subjects.find((s) => s.averageSubject === subjectId)) {
          subjects.push(subject);
        }
      });
      return subjects;
    }, []);

    allSubjects.forEach((subject) => {
      const subjectId = subject.averageSubject;

      // Create an array of students and their scores for the current subject
      const studentsWithScores = information.map((studentInfo) => {
        const studentId = studentInfo.student._id;
        const studentStream = studentInfo.student.stream;
        const studentScoreMap = studentSubjectScores.get(studentId);
        return { studentId, stream: studentStream, score: studentScoreMap ? studentScoreMap.get(subjectId) : 0 };
      });

      // Sorting students based on score for the current subject
      const sortedStudentsByScore = studentsWithScores.slice().sort((a, b) => b.score - a.score);

      // Assign positions for each student based on score
      assignPosition(sortedStudentsByScore, 'positionScore', subjectId);
      // Assign positions within each stream
      assignPosition(sortedStudentsByScore, 'positionScoreStream', subjectId, 'stream');
    });

    // Function to assign positions for each student
    function assignPosition(sortedStudents, positionField, subjectId, streamField = null) {
      let currentPosition = 1;
      for (let i = 0; i < sortedStudents.length; i++) {
        const student = sortedStudents[i];
        const studentInfo = information.find((info) => info.student._id === student.studentId);
        const subjectIndex = studentInfo.info.averageBySubject.findIndex((subj) => subj.averageSubject === subjectId);
        if (subjectIndex !== -1) {
          studentInfo.info.averageBySubject[subjectIndex][positionField] = currentPosition;
          // Assign positions within the same stream
          if (streamField) {
            const studentsWithSameStream = sortedStudents.filter((s) => s[streamField] === student[streamField]);
            const sameStreamPosition = currentPosition + Math.floor((studentsWithSameStream.length - 1) / 2);
            studentInfo.info.averageBySubject[subjectIndex][positionField + streamField] = sameStreamPosition;
          }
        }
        currentPosition++;
      }
    }


    information.forEach((studentInfo) => {

      // here put the logic of finding totalMarks in student info due to sum of averageBySubject score
      studentInfo.total += studentInfo.info?.averageBySubject.reduce((sum, subject) => sum + (subject.score || 0), 0);

      studentInfo.average = subjectCount > 0 ? studentInfo.total / subjectCount : 0

      //  studentInfo.average2 = numOfMarkedSubjects > 0 ? studentInfo.total / numOfMarkedSubjects : 0
    })


    const sortedStudentsByAverage = information.slice().sort((a, b) => b.average - a.average);
    assignPositions(sortedStudentsByAverage, 'position');

    // Sorting students based on average2
    const sortedStudentsByAverage2 = information.slice().sort((a, b) => b.average2 - a.average2);
    assignPositions(sortedStudentsByAverage2, 'position2');

    // Function to assign positions
    function assignPositions(sortedStudents, positionField) {
      let currentPosition = 1;
      for (let i = 0; i < sortedStudents.length; i++) {
        if (i > 0 && sortedStudents[i].average === sortedStudents[i - 1].average) {
          sortedStudents[i][positionField] = sortedStudents[i - 1][positionField];
        } else {
          sortedStudents[i][positionField] = currentPosition;
        }
        currentPosition++;
      }
    }

    // Sorting students based on average within each stream
    const streamAverages = {};
    information.forEach((studentInfo) => {
      const streamId = studentInfo.student.stream
      if (!streamAverages[streamId]) {
        streamAverages[streamId] = [];
      }
      streamAverages[streamId].push(studentInfo);
    });

    for (const streamId in streamAverages) {
      const studentsInStream = streamAverages[streamId];
      const sortedStudentsByAverageInStream = studentsInStream.slice().sort((a, b) => b.average - a.average);
      assignPositions(sortedStudentsByAverageInStream, 'positionStream');
    }

    // Sorting students based on average2 within each stream
    const streamAverages2 = {};
    information.forEach((studentInfo) => {
      const streamId = studentInfo.student.stream
      if (!streamAverages2[streamId]) {
        streamAverages2[streamId] = [];
      }
      streamAverages2[streamId].push(studentInfo);
    });

    for (const streamId in streamAverages2) {
      const studentsInStream = streamAverages2[streamId];
      const sortedStudentsByAverage2InStream = studentsInStream.slice().sort((a, b) => b.average2 - a.average2);
      assignPositions(sortedStudentsByAverage2InStream, 'positionStream2');
    }

    return information;
  } catch (error) {
    console.log(error.message);
    return { error: error.message };
  }
};

export const payrollStatus = [
  "included in payroll",
  "not included in payroll"
]

// calculate paye
// Function to calculate PAYE based on the provided formula
const calculatePAYE = (taxable_amount) => {
  try {
    if (taxable_amount <= 270000) {
      return 0;
    } else if (taxable_amount < 520000) {
      return 0.08 * (taxable_amount - 270000);
    } else if (taxable_amount < 760000) {
      return 0.2 * (taxable_amount - 520000) + 20000;
    } else if (taxable_amount < 1000000) {
      return 0.25 * (taxable_amount - 760000) + 68000;
    } else {
      return 0.3 * (taxable_amount - 1000000) + 128000;
    }
  } catch (error) {
    console.log(`Paye error: ${error.message}`)
  }
}

// Helper function to check if a deadline is passed
const isDeadlinePassed = (deadline) => {
  try {
    const now = time.currentDate;
    return now > deadline;
  } catch (error) {
    console.log(`Deadline determination error: ${error.message}`)
  }
};
// calculate payroll
export const payroll = (data) => {
  try {
    // Validate if there is data
    if (data && data.length > 0) {
      // Loop for each data
      const payrollData = data?.map((item) => {
        // Validate if salary is present and the user is included in payroll
        if ( item.salary_status === "included_in_payroll") {
          // Convert salary to a number
          const basic_salary = Number(item.salary);

          // Calculate applicable allowances and deductions based on deadlines
          const applicableAllowances = item.allowances?.reduce((acc, allowance) => {
            if (allowance.name.category === "fixed" || (allowance.name.category === "monthly" && !isDeadlinePassed(time.formatDate(allowance.deadline)))) {
              acc.push(allowance);
            }
            return acc;
          }, []);

          const applicableDeductions = item.deductions?.reduce((acc, deduction) => {
            if (deduction.name.category === "fixed" || (deduction.name.category === "monthly" && !isDeadlinePassed(time.formatDate(deduction.deadline)))) {
              acc.push(deduction);
            }
            return acc;
          }, []);

          // Calculate total allowances
          const total_allowance = applicableAllowances?.length > 0 ? applicableAllowances.reduce((acc, allowance) => {
            if (string.isNotEmpty(allowance.percentage)) {
              // If percentage is present, convert it to a number and calculate based on basic salary
              const percentage = Number(allowance.percentage);
              acc += basic_salary * (percentage / 100);
            } else if (string.isNotEmpty(allowance.amount)) {
              // If amount is present, use it directly
              acc += Number(allowance.amount);
            }
            return acc;
          }, 0) : 0;

          // Determine pensionable allowance
          const pensionable_allowance = applicableAllowances?.length > 0 ? applicableAllowances.reduce((acc, allowance) => {
            return allowance.name.pension_status === "included" ? acc + (Number(allowance.amount) || basic_salary * (Number(allowance.percentage) / 100)) : acc;
          }, 0) : 0;

          // // calculate non pensionable allowance
          // const non_pensionable_allowance = applicableAllowances?.length > 0 ? applicableAllowances.reduce((acc, allowance) => {
          //   return allowance.name.pension_status === "not_included" ? acc + (Number(allowance.amount) || basic_salary * (Number(allowance.percentage) / 100)) : acc;
          // }, 0) : 0;

          // // calculate taxable for non pensionable allowance
          // const taxable_non_pensionable_allowance = applicableAllowances?.length > 0 ? applicableAllowances.reduce((acc, allowance) => {
          //   return (allowance.name.pension_status === "not_included" && allowance.name.type === "taxable") ? acc + (Number(allowance.amount) || basic_salary * (Number(allowance.percentage) / 100)) : acc;
          // }, 0) : 0;

          // calculate non taxable for non pensionable allowances
          const non_taxable_non_pensionable_allowance = applicableAllowances?.length > 0 ? applicableAllowances.reduce((acc, allowance) => {
            return (allowance.name.pension_status === "not_included" && allowance.name.type === "non_taxable") ? acc + (Number(allowance.amount) || basic_salary * (Number(allowance.percentage) / 100)) : acc;
          }, 0) : 0;

          // calculate taxable for non pensionable deduction
          // const taxable_deduction = applicableDeductions?.length > 0 ? applicableDeductions.reduce((acc, deduction) => {
          //   return (deduction.name.type === "taxable") ? acc + (Number(deduction.amount) || basic_salary * (Number(deduction.percentage) / 100)) : acc;
          // }, 0) : 0;

          // calculate non taxable for non pensionable deductions
          const non_taxable_deduction = applicableDeductions?.length > 0 ? applicableDeductions.reduce((acc, deduction) => {
            return (deduction.name.type === "non_taxable") ? acc + (Number(deduction.amount) || basic_salary * (Number(deduction.percentage) / 100)) : acc;
          }, 0) : 0;

          
          // Calculate gross salary by summing basic salary and allowances
          const gross_salary = basic_salary + total_allowance;
       
          // calculate pension
          const total_pension = item.pensions?.length > 0 ? item.pensions.reduce((acc, pension) => {
            return acc + (((pension.name.based_on === "basic_salary" ? basic_salary : gross_salary) + pensionable_allowance) * (Number(pension.employee_percentage) / 100))
          }, 0) : 0;

          // Calculate taxable amount
          const taxable_amount = gross_salary - non_taxable_non_pensionable_allowance - non_taxable_deduction;

          // Calculate PAYE
          const paye = calculatePAYE(taxable_amount);

          // Calculate total deductions
          const total_deduction = applicableDeductions?.length > 0 ? applicableDeductions.reduce((acc, deduction) => {
            if (string.isNotEmpty(deduction.percentage)) {
              // If percentage is present, convert it to a number and calculate based on basic salary
              const percentage = Number(deduction.percentage);
              acc += (basic_salary * (percentage / 100) + total_pension + paye);
            } else if (string.isNotEmpty(deduction.amount)) {
              // If amount is present, use it directly
              acc += (Number(deduction.amount) + total_pension + paye);
            }
            return acc;
          }, 0) : 0;

          // Calculate net payment
          const net_pay = gross_salary - total_deduction;

          return {
            ...item,
            gross_salary,
            total_allowance,
            total_deduction,
            total_pension,
            taxable_amount,
            paye, // You need to calculate PAYE here,
            net_pay,
          };
        }
        return null; // Exclude users not included in payroll
      }).filter(Boolean);

      return payrollData;
    }
  } catch (error) {
    console.log(`Payroll error: ${error.message}`);
  }
};
