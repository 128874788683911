import React, { Suspense } from "react";
import { ThemeProvider } from "./context/themeContext";
import { storage, user } from "./helpers";
import { string } from 'fast-web-kit'
import initial_state from './hooks/initial_state'
import { BrowserRouter as Router } from "react-router-dom"
import reducer from './hooks/reducer'
import API from './helpers/application'
import routing from './routes/routing'
import Spinner from "./components/spinner";
import { GlobalContainer } from "./components/global";
import Drawer from "./components/drawer";

function App() {

  // state management
  const [state, dispatch] = React.useReducer(reducer, initial_state)

  const history = window.history;

  React.useEffect(() => {
    const handlePopstate = (event) => {
      // Check if can go back or forward
      if (event.state) {
        // Go back or forward to the previous/next state in history
        history.go(event.state.delta);
      }
    };

    window.addEventListener('popstate', handlePopstate);

    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, [history]);

  // useEffect to empty notification state after 5sec
  React.useEffect(() => {
    if (string.isNotEmpty(state.notification.message)) {
      setTimeout(() => {

        dispatch({ type: "notification", value: { message: '', type: '' } }
        )
      }, 5000)
    }

    // check user in local storage
    const user = storage.retrieve('user')

    if (user) {
      /* change  state of authenticated to true if user exists*/
      dispatch({ type: 'authenticated', value: true })
    }

  }, [state.notification]);

  function authenticate(action, options) {
    try {

      if (action === 'create') {
        /* NOTE: you can encrypt user data for max security */
        storage.store(options.key, options.value)
        dispatch({ type: 'authenticated', value: true })
        dispatch({ type: "notification", value: { message: 'You have been logged in', type: "success" } })
      }
      else if (action === 'logout') {
        storage.clear()
        window.location.reload()
        dispatch({ type: 'authenticated', value: false })
        dispatch({ type: "notification", value: { message: 'You have been logged out', type: "success" } })
      }

      window.location.reload()

    } catch (error) {
      console.error(error)
    }
  }

  // applcation props
  const application = {
    reducer: { state, dispatch },
    application: new API(state, dispatch),
    authenticate
  }
  
  return (
    <ThemeProvider>
      <Suspense fallback={<Spinner />}>
        {state.loading ? <Spinner /> : null}
        <Router>
          {
            state.authenticated ?
              <Drawer
                user={user}
                authenticate={authenticate}
                type={state.notification.type}
                message={state.notification.message}
                authenticated={state.authenticated}
              >
                {routing(application)}
              </Drawer>
              :
              <GlobalContainer
                type={state.notification.type}
                message={state.notification.message}
              >
                {routing(application)}
              </GlobalContainer>
          }
        </Router>
      </Suspense>
    </ThemeProvider>
  );
}

export default App;
